

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';

const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;




const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;



const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function PRPvampirefaq({setIsModalOpen}) {
    return (
        <Section py={4} id="faq" bg="#ffffff" className=" ">
          <Container className="pt-3 pb-3" data-aos="fade-up"
                     data-aos-duration="500"
                     data-aos-once="false"
                     data-aos-delay="100">
            <Row className="align-items-center pb-4">
              <Col md="9" lg="12" className="">

                <Iwrap>


                  <Itext as="h2">Questions About <span>Lip Filler</span></Itext>
                </Iwrap>
                <SecondText>
                  Frequently asked questions
                </SecondText>


              </Col>
            </ Row>


            <Row className="">

              <Col lg="6">

                <Col
                  lg="12"
                  md="6"
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="50"
                >
                  <Accordion allowZeroExpanded>

                    <AccordionItem>
                      <AccordionItemHeading>
                         <AccordionItemButton className="text-[14px] pl-2">
                          What does the Lip Filler Treatment London involve? </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          The treatment is carried out by injecting dermal fillers strategically to the lips, with a
                          needle, delivering results that are desirable and suitable for the patient. Treatment results
                          are instant and the treatment time typically lasts between 10-15 minutes.


                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>


                  </Accordion>
                </Col>

                <Col
                  lg="12"
                  md="6"
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="50"
                >
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                         <AccordionItemButton className="text-[14px] pl-2">

                          What happens during Lip Filler Treatment London?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          We start the appointment with a thorough consultation to understand your requirements and
                          suitability. Once we have a full understanding and you’re happy to proceed, we start the
                          treatment

                          We always give our professional and honest advice before starting your treatment. Throughout
                          the treatment, we want you to be as involved as possible


                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                  </Accordion>
                </Col>

                <Col
                  lg="12"
                  md="6"
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="50"
                >
                  <Accordion allowZeroExpanded>

                    <AccordionItem>
                      <AccordionItemHeading>
                         <AccordionItemButton className="text-[14px] pl-2">
                          How long does the Lip Filler London take? </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Lip Filler Treatment takes no longer than 15 minutes; patients will see immediate results,
                          with no recovery time needed, and you can get back to your daily activities or work the very
                          same day

                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>


                  </Accordion>
                </Col>

                <Col
                  lg="12"
                  md="6"
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="50"
                >
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                         <AccordionItemButton className="text-[14px] pl-2">
                          What are the side effects and does Lip Filler Treatment London hurt? </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Numbing cream is applied to the area to be injected and only when it is numbed, the treatment
                          is done, making your experience as comforting as possible. Based on all our previous patients,
                          the pain was mild and some patients felt no pain at all. <br /> <br />

                          Slight tenderness, redness and swelling are to be expected right after the treatment, and
                          sometimes mild bruising. However, our practitioner will talk you through everything during the
                          consultation on the day.

                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>


                  </Accordion>
                </Col>


                <Col
                  lg="12"
                  md="6"
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="50"
                >
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                         <AccordionItemButton className="text-[14px] pl-2">
                          Is there recovery following Lip Filler Treatment London? Will the treatment leave any
                          marks? </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          No recovery time needed. You can get straight back to your daily routine, without taking time
                          off work. <br /> <br />

                          You will see instant results, with final results taking 2 weeks to allow any swelling and
                          bruising to settle and for the lips to completely heal. Redness at the injected site is normal
                          and expected as with all injectable related treatments.<br /> <br />

                          More on what to expect during and after the treatment will be thoroughly explained during the
                          consultation before the treatment begins


                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                  </Accordion>
                </Col>


                <Col
                  lg="12"
                  md="6"
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="50"
                >
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                         <AccordionItemButton className="text-[14px] pl-2">
                          Is Lip Filler Treatment UK safe? </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Yes. Hyaluronic acid based dermal fillers are derived naturally and not animal based. It is
                          known as one of nature’s most versatile and is made with extensive research in the laboratory.<br />
                          <br />

                          Over time the fillers get dissolved naturally in the skin as the body is able to break the
                          filler down and results of the treatment gradually fade. The filler breaks down and is
                          re-absorbed by the body, just like the natural occurring hyaluronic acid in the skin.
                          Hyaluronic acid has gained a reputation for being safe, effective and a good alternative to
                          surgery.


                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                  </Accordion>
                </Col>


                <Col
                  lg="12"
                  md="6"
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="50"
                >
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                         <AccordionItemButton className="text-[14px] pl-2">

                          What happens after 6 months?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Patients feel more confident after the treatment since they will see immediate and
                          natural-looking results. Patients will notice a change in their appearance thereafter. <br />
                          <br />One of the best things about this treatment is that it is not a permanent solution,
                          making it excellent for people who want to experiment with the new look without committing to
                          surgery or entirely changing their face. The lips can be tweaked as your face changes over
                          time to keep the result. As the face evolves over time, tweaks can be made to keep the result
                          consistent. <br /> <br />

                          The treatment's longevity is determined by a number of factors, including the patient's
                          metabolic rate, lifestyle factors, and health.


                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                  </Accordion>
                </Col>


                <Col
                  lg="12"
                  md="6"
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="50"
                >
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                         <AccordionItemButton className="text-[14px] pl-2">
                          How much is Lip Filler treatment UK? </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          At Dermamina London prices are dependent on quantity chosen: Up to 0.7ml - £250, 1ml - £300,
                          2ml - £480. If you are unsure of the quantity to choose from, you can drop our friendly team a
                          message so we can assess and advice you accordingly.


                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                  </Accordion>
                </Col>

              </Col>
              <Col lg="6">
                <Col
                  lg="12"
                  md="6"
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="50"
                >
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                         <AccordionItemButton className="text-[14px] pl-2">
                          How do I prepare for the Lip Filler treatment? </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>


                          It is best to arrive for your appointment with your skin as best prepared for the treatment.
                          This includes, less makeup on the face, plenty of rest the night before so you are calm and
                          relaxed during the treatment, hydrated and had plenty to eat. <br /><br />

                          Please arrive on time, with all online forms completed and with realistic expectations. Dermal
                          fillers are not intended to completely change your appearance, but enhance what you already
                          have. <br /><br />

                          As soon as you book you will be sent pre care advise, included in your form, to help you
                          prepare for the treatment. Our pre care advice includes:
                          <br /><br />
                          - Avoid taking any blood thinning medications such as aspirin and ibuprofen for one week prior
                          to your appointment. This is to prevent bruising during and after the treatment. Please
                          consult with your medical practitioner before stopping any medication <br /><br />

                          - Avoid booking if you had any major dental treatments or plan to after the treatment. This
                          includes root canal extraction/treatment and deep filing. After 2 weeks it will be fine <br /><br />

                          - Arnica tablets can be taken before and after the treatment to reduce the risk of bruising
                          (optional) <br /><br />

                          - To lessen the risk of bleeding and bruising, do not consume alcohol 2-3 days prior or
                          following your appointment


                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                  </Accordion>
                </Col>


                <Col
                  lg="12"
                  md="6"
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="50"
                >
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                         <AccordionItemButton className="text-[14px] pl-2">
                          What should I avoid after? </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>


                          It is critical that all patients follow the post-treatment instructions and report any adverse
                          effects or concerns that they may have. Post-treatment instructions will be included in the
                          forms sent to your email once a treatment has been scheduled to inform and assist you with any
                          necessary preparations.<br /><br />

                          To remind you, an after-care email is issued immediately after the treatment. We make sure
                          you're safe during and after your treatment at Dermamina London. Post treatment advice
                          includes:<br /><br />
                          - Avoid strenuous exercise immediately following for 24 hours <br /><br />
                          - Avoid touching/ rubbing areas injected, avoid applying any pressure on treated
                          areas<br /><br />
                          - Avoid alcohol consumption 24 hours before and after <br /><br />
                          - Avoid hot drinks for 12 hours <br /><br />
                          - Avoid applying makeup for 12 hours <br /><br />
                          - Avoid other treatments for 10-14 days such as microdermabrasion, laser treatment, facials
                          etc.


                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                  </Accordion>
                </Col>


                <Col
                  lg="12"
                  md="6"
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="50"
                >
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                         <AccordionItemButton className="text-[14px] pl-2">
                          What If I am unhappy with the result? </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>


                          We always aim for natural looking results, suitable for your lip shape. We will also ensure
                          during the consultation provided that we are aware of what you are looking for so we can meet
                          your expectation. <br /> <br />

                          With the administration of hyaluronidase, the treatment is totally reversible. This implies
                          the lips can be restored to its normal shape in a matter of days. For those patients that like
                          the result of the treatment, it can be topped up safely over the years to maintain the result.


                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                  </Accordion>
                </Col>


                <Col
                  lg="12"
                  md="6"
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="50"
                >
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                         <AccordionItemButton className="text-[14px] pl-2">
                          Will I get a consultation before the treatment?

                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>

                          Yes, the appointment will begin with a private consultation that includes a full facial
                          assessment and discussion on the treatment, with what to expect during and after the treatment
                          explained. Then a tailored treatment plan is devised


                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                  </Accordion>
                </Col>


                <Col
                  lg="12"
                  md="6"
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="50"
                >
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                         <AccordionItemButton className="text-[14px] pl-2">
                          What if I change my mind after the consultation?


                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>

                          In the case of changing your mind after the consultation, you will be charged £20 for the
                          consultation and the remaining will be refunded back to the account you made the payment in.
                          This refers to the deposit needed to be paid when booking

                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                  </Accordion>
                </Col>


                <Col
                  lg="12"
                  md="6"
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="50"
                >
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                         <AccordionItemButton className="text-[14px] pl-2">
                          Am I suitable for the Non-Surgical Lip Filler Treatment?


                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>

                          At Dermamina, we strive for natural looking results, tailored to individual suitability,
                          aiming to enhance your beauty rather than completely transforming your look. <br /> <br />

                          We suggest booking in for a consultation if you are unsure whether the treatment will help
                          achieve the results you are looking for.<br /> <br />

                          You may also get in contact with a member of team and explain your concern with explanations
                          of your lip shape along with what you wish to achieve. It is important to speak with the
                          practitioner during the consultation about your expectations in order to confirm whether or
                          not they are achievable.<br /> <br />


                          All patients must be 18+ <br /> <br />

                          We will ask for photo ID if aged 18 or 19 (i.e. passport copy, driving licence etc)

                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                  </Accordion>
                </Col>


                <Col
                  lg="12"
                  md="6"
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="50"
                >
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                         <AccordionItemButton className="text-[14px] pl-2">
                          Can I have other dermal filler treatments along with this treatment?


                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>

                          Yes, more than one dermal filler treatments can be done all in one day, upon assessment and
                          suitability. Did you know that we offer treatment packages? You can choose to add certain
                          amount of filler in the desired areas on the face all in one session with professional and
                          honest advice from our lovely practitioner. <br /> <br />


                          Popular options along with this treatment is the nose and chin filler treatments.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                  </Accordion>
                </Col>


                <Col
                  lg="12"
                  md="6"
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="50"
                >
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                         <AccordionItemButton className="text-[14px] pl-2">
                          Lip Filler Treatment in London? Why choose Dermamina London?


                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>

                          We are renowned for providing natural looking yet life changing results, and being completely
                          honest with our patients. If we feel a treatment is not suitable for you, we will not
                          recommend for you to go ahead with it.


                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                  </Accordion>
                </Col>

              </Col>

            </Row>



            <div data-aos="fade-up"
               onClick={() => setIsModalOpen(true)}
               data-aos-duration="500"
               data-aos-once="false"
               data-aos-delay="300" className=" d-block d-sm-none pt-2"
               activeClassName="active"
            >
              <Bookbutton>Book appointment</Bookbutton>
            </div>


          </Container>
        </Section>


    );
}






